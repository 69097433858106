define('@fortawesome/pro-regular-svg-icons', ['exports'], (function (exports) { 'use strict';

  const faBadge = {
    prefix: 'far',
    iconName: 'badge',
    icon: [512, 512, [], "f335", "M256 48c-24.1 0-45.1 13.5-55.7 33.5C194.9 91.7 183 96.6 172 93.2c-21.6-6.6-46.1-1.4-63.1 15.7S86.6 150.4 93.2 172c3.4 11-1.5 22.9-11.7 28.2C61.5 210.9 48 231.9 48 256s13.5 45.1 33.5 55.7C91.7 317.1 96.6 329 93.2 340c-6.6 21.6-1.4 46.1 15.7 63.1s41.5 22.3 63.1 15.7c11-3.4 22.9 1.5 28.2 11.7c10.6 20 31.6 33.5 55.7 33.5s45.1-13.5 55.7-33.5c5.4-10.2 17.2-15.1 28.2-11.7c21.6 6.6 46.1 1.4 63.1-15.7s22.3-41.5 15.7-63.1c-3.4-11 1.5-22.9 11.7-28.2c20-10.6 33.5-31.6 33.5-55.7s-13.5-45.1-33.5-55.7c-10.2-5.4-15.1-17.2-11.7-28.2c6.6-21.6 1.4-46.1-15.7-63.1S361.6 86.6 340 93.2c-11 3.4-22.9-1.5-28.3-11.7C301.1 61.5 280.1 48 256 48zm-88.1-4.6C188.2 17 220.1 0 256 0s67.8 17 88.1 43.4c33-4.3 67.6 6.2 93 31.6s35.9 60 31.6 93C495 188.2 512 220.1 512 256s-17 67.8-43.4 88.1c4.3 33-6.2 67.6-31.6 93s-60 35.9-93 31.6C323.8 495 291.9 512 256 512s-67.8-17-88.1-43.4c-33 4.3-67.6-6.2-93-31.6s-35.9-60-31.6-93C17 323.8 0 291.9 0 256s17-67.8 43.4-88.1c-4.3-33 6.2-67.6 31.6-93s60-35.9 93-31.6z"]
  };
  const faShuffle = {
    prefix: 'far',
    iconName: 'shuffle',
    icon: [512, 512, [128256, "random"], "f074", "M425 31l80 80c9.4 9.4 9.4 24.6 0 33.9l-80 80c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l39-39L352 152c-12.6 0-24.4 5.9-32 16l-46 61.3-30-40 37.6-50.1C298.2 117 324.3 104 352 104l78.1 0L391 65c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0zM204 322.7l-37.6 50.1C149.8 395 123.7 408 96 408l-72 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l72 0c12.6 0 24.4-5.9 32-16l46-61.3 30 40zM391 287c9.4-9.4 24.6-9.4 33.9 0l80 80c9.4 9.4 9.4 24.6 0 33.9l-80 80c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l39-39L352 408c-27.7 0-53.8-13-70.4-35.2L128 168c-7.6-10.1-19.4-16-32-16l-72 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l72 0c27.7 0 53.8 13 70.4 35.2L320 344c7.6 10.1 19.4 16 32 16l78.1 0-39-39c-9.4-9.4-9.4-24.6 0-33.9z"]
  };
  const faRotateLeft = {
    prefix: 'far',
    iconName: 'rotate-left',
    icon: [512, 512, ["rotate-back", "rotate-backward", "undo-alt"], "f2ea", "M140.8 122.9C171.7 96.2 212 80 256 80c97.2 0 176 78.8 176 176s-78.8 176-176 176c-39.7 0-76.2-13.1-105.6-35.2c-10.6-8-25.6-5.8-33.6 4.8s-5.8 25.6 4.8 33.6C159 463.3 205.6 480 256 480c123.7 0 224-100.3 224-224S379.7 32 256 32c-57.3 0-109.6 21.5-149.2 56.9L76.3 58.3C69.7 51.7 60.7 48 51.3 48C31.8 48 16 63.8 16 83.3L16 200c0 13.3 10.7 24 24 24l116.7 0c19.5 0 35.3-15.8 35.3-35.3c0-9.4-3.7-18.3-10.3-25l-40.8-40.8zm-76.8-9L126.1 176 64 176l0-62.1z"]
  };
  const faQuestion = {
    prefix: 'far',
    iconName: 'question',
    icon: [320, 512, [10067, 10068, 61736], "3f", "M64 160c0-44.2 35.8-80 80-80l32 0c44.2 0 80 35.8 80 80l0 4.6c0 24.1-12 46.6-32.1 59.9l-52.3 34.9C149.4 274.2 136 299.2 136 326l0 2c0 13.3 10.7 24 24 24s24-10.7 24-24l0-2c0-10.7 5.3-20.7 14.2-26.6l52.3-34.9c33.4-22.3 53.4-59.7 53.4-99.8l0-4.6c0-70.7-57.3-128-128-128l-32 0C73.3 32 16 89.3 16 160c0 13.3 10.7 24 24 24s24-10.7 24-24zm96 320a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"]
  };
  const faBadgeDollar = {
    prefix: 'far',
    iconName: 'badge-dollar',
    icon: [512, 512, [], "f645", "M200.3 81.5C210.9 61.5 231.9 48 256 48s45.1 13.5 55.7 33.5C317.1 91.7 329 96.6 340 93.2c21.6-6.6 46.1-1.4 63.1 15.7s22.3 41.5 15.7 63.1c-3.4 11 1.5 22.9 11.7 28.2c20 10.6 33.5 31.6 33.5 55.7s-13.5 45.1-33.5 55.7c-10.2 5.4-15.1 17.2-11.7 28.2c6.6 21.6 1.4 46.1-15.7 63.1s-41.5 22.3-63.1 15.7c-11-3.4-22.9 1.5-28.2 11.7c-10.6 20-31.6 33.5-55.7 33.5s-45.1-13.5-55.7-33.5c-5.4-10.2-17.2-15.1-28.2-11.7c-21.6 6.6-46.1 1.4-63.1-15.7S86.6 361.6 93.2 340c3.4-11-1.5-22.9-11.7-28.2C61.5 301.1 48 280.1 48 256s13.5-45.1 33.5-55.7C91.7 194.9 96.6 183 93.2 172c-6.6-21.6-1.4-46.1 15.7-63.1S150.4 86.6 172 93.2c11 3.4 22.9-1.5 28.2-11.7zM256 0c-35.9 0-67.8 17-88.1 43.4c-33-4.3-67.6 6.2-93 31.6s-35.9 60-31.6 93C17 188.2 0 220.1 0 256s17 67.8 43.4 88.1c-4.3 33 6.2 67.6 31.6 93s60 35.9 93 31.6C188.2 495 220.1 512 256 512s67.8-17 88.1-43.4c33 4.3 67.6-6.2 93-31.6s35.9-60 31.6-93C495 323.8 512 291.9 512 256s-17-67.8-43.4-88.1c4.3-33-6.2-67.6-31.6-93s-60-35.9-93-31.6C323.8 17 291.9 0 256 0zm20.9 140.4c0-11-9-20-20-20s-20 9-20 20l0 17.4c-8.8 1.8-17.7 4.8-25.7 9.6c-15 8.9-27.7 24.4-27.6 46.9c.1 21.5 12.6 35.1 26.3 43.4c11.9 7.2 27 11.7 39.1 15.4l1.9 .6c13.9 4.2 24.4 7.6 31.7 12.2c6.1 3.8 7.5 6.8 7.6 11c.1 6.5-2.5 10.4-7.6 13.6c-6 3.7-15.2 5.9-25.1 5.6c-12.7-.4-24.6-4.4-39.7-9.6c0 0 0 0 0 0s0 0 0 0s0 0 0 0s0 0 0 0c-2.6-.9-5.2-1.8-8-2.7c-10.5-3.5-21.8 2.2-25.3 12.6s2.2 21.8 12.6 25.3c2.2 .7 4.5 1.5 6.8 2.3c9.7 3.3 20.9 7.2 32.8 9.6l0 18c0 11 9 20 20 20s20-9 20-20l0-17.1c9.3-1.7 18.6-4.9 26.8-10.1c15.3-9.5 26.8-25.7 26.5-48.1c-.3-21.6-12.4-35.6-26.3-44.3c-12.5-7.9-28.3-12.6-40.8-16.4l-.6-.2c-14.1-4.3-24.6-7.5-31.9-11.9c-6.2-3.7-7-6.3-7-9.3c0-5.1 2.1-8.8 7.9-12.3c6.5-3.8 16-6 25-5.8c10.9 .2 22.9 2.6 35.2 5.8c10.7 2.8 21.6-3.5 24.5-14.2s-3.5-21.6-14.2-24.5c-7.8-2.1-16.3-4.1-25.1-5.4l0-17.3z"]
  };
  const faSliders = {
    prefix: 'far',
    iconName: 'sliders',
    icon: [512, 512, ["sliders-h"], "f1de", "M0 416c0 13.3 10.7 24 24 24l59.7 0c10.2 32.5 40.5 56 76.3 56s66.1-23.5 76.3-56L488 440c13.3 0 24-10.7 24-24s-10.7-24-24-24l-251.7 0c-10.2-32.5-40.5-56-76.3-56s-66.1 23.5-76.3 56L24 392c-13.3 0-24 10.7-24 24zm128 0a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM320 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm32-80c-35.8 0-66.1 23.5-76.3 56L24 232c-13.3 0-24 10.7-24 24s10.7 24 24 24l251.7 0c10.2 32.5 40.5 56 76.3 56s66.1-23.5 76.3-56l59.7 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-59.7 0c-10.2-32.5-40.5-56-76.3-56zM192 128a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm76.3-56C258.1 39.5 227.8 16 192 16s-66.1 23.5-76.3 56L24 72C10.7 72 0 82.7 0 96s10.7 24 24 24l91.7 0c10.2 32.5 40.5 56 76.3 56s66.1-23.5 76.3-56L488 120c13.3 0 24-10.7 24-24s-10.7-24-24-24L268.3 72z"]
  };
  const faXmark = {
    prefix: 'far',
    iconName: 'xmark',
    icon: [384, 512, [128473, 10005, 10006, 10060, 215, "close", "multiply", "remove", "times"], "f00d", "M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z"]
  };
  const faChevronRight = {
    prefix: 'far',
    iconName: 'chevron-right',
    icon: [320, 512, [9002], "f054", "M305 239c9.4 9.4 9.4 24.6 0 33.9L113 465c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l175-175L79 81c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L305 239z"]
  };
  const faPersonSeat = {
    prefix: 'far',
    iconName: 'person-seat',
    icon: [384, 512, [], "e21e", "M144 128a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm-8 92c0-6.6 5.4-12 12-12s12 5.4 12 12l0 92-16 0c-4.4 0-8-3.6-8-8l0-84zm72 92l0-56 56 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-57.2 0c-5.6-27.4-29.8-48-58.8-48c-33.1 0-60 26.9-60 60l0 84c0 30.9 25.1 56 56 56l40 0 54 0c12 0 22.2 8.9 23.8 20.8l10.4 78.3C273.8 471.1 284 480 296 480l64 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-43 0-7.7-57.5C304.6 338.7 274.1 312 238 312l-30 0zM48 152c0-13.3-10.7-24-24-24s-24 10.7-24 24L0 328c0 66.3 53.7 120 120 120l80 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-80 0c-39.8 0-72-32.2-72-72l0-176z"]
  };
  const faCheck = {
    prefix: 'far',
    iconName: 'check',
    icon: [448, 512, [10003, 10004], "f00c", "M441 103c9.4 9.4 9.4 24.6 0 33.9L177 401c-9.4 9.4-24.6 9.4-33.9 0L7 265c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l119 119L407 103c9.4-9.4 24.6-9.4 33.9 0z"]
  };
  const faTriangleExclamation = {
    prefix: 'far',
    iconName: 'triangle-exclamation',
    icon: [512, 512, [9888, "exclamation-triangle", "warning"], "f071", "M248.4 84.3c1.6-2.7 4.5-4.3 7.6-4.3s6 1.6 7.6 4.3L461.9 410c1.4 2.3 2.1 4.9 2.1 7.5c0 8-6.5 14.5-14.5 14.5l-387 0c-8 0-14.5-6.5-14.5-14.5c0-2.7 .7-5.3 2.1-7.5L248.4 84.3zm-41-25L9.1 385c-6 9.8-9.1 21-9.1 32.5C0 452 28 480 62.5 480l387 0c34.5 0 62.5-28 62.5-62.5c0-11.5-3.2-22.7-9.1-32.5L304.6 59.3C294.3 42.4 275.9 32 256 32s-38.3 10.4-48.6 27.3zM288 368a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm-8-184c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 96c0 13.3 10.7 24 24 24s24-10.7 24-24l0-96z"]
  };
  const faExclamationTriangle = faTriangleExclamation;

  exports.faBadge = faBadge;
  exports.faBadgeDollar = faBadgeDollar;
  exports.faCheck = faCheck;
  exports.faChevronRight = faChevronRight;
  exports.faExclamationTriangle = faExclamationTriangle;
  exports.faPersonSeat = faPersonSeat;
  exports.faQuestion = faQuestion;
  exports.faRotateLeft = faRotateLeft;
  exports.faShuffle = faShuffle;
  exports.faSliders = faSliders;
  exports.faXmark = faXmark;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
