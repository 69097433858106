define('@fortawesome/pro-solid-svg-icons', ['exports'], (function (exports) { 'use strict';

  const faBadge = {
    prefix: 'fas',
    iconName: 'badge',
    icon: [512, 512, [], "f335", "M340.9 51.1C324.8 20.7 292.8 0 256 0s-68.8 20.7-84.9 51.1C138.2 41 101 49 75 75s-34 63.3-23.9 96.1C20.7 187.2 0 219.2 0 256s20.7 68.8 51.1 84.9C41 373.8 49 411 75 437s63.3 34 96.1 23.9C187.2 491.3 219.2 512 256 512s68.8-20.7 84.9-51.1C373.8 471 411 463 437 437s34-63.3 23.9-96.1C491.3 324.8 512 292.8 512 256s-20.7-68.8-51.1-84.9C471 138.2 463 101 437 75s-63.3-34-96.1-23.9z"]
  };
  const faShuffle = {
    prefix: 'fas',
    iconName: 'shuffle',
    icon: [512, 512, [128256, "random"], "f074", "M403.8 34.4c12-5 25.7-2.2 34.9 6.9l64 64c6 6 9.4 14.1 9.4 22.6s-3.4 16.6-9.4 22.6l-64 64c-9.2 9.2-22.9 11.9-34.9 6.9s-19.8-16.6-19.8-29.6l0-32-32 0c-10.1 0-19.6 4.7-25.6 12.8L284 229.3 244 176l31.2-41.6C293.3 110.2 321.8 96 352 96l32 0 0-32c0-12.9 7.8-24.6 19.8-29.6zM164 282.7L204 336l-31.2 41.6C154.7 401.8 126.2 416 96 416l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32l64 0c10.1 0 19.6-4.7 25.6-12.8L164 282.7zm274.6 188c-9.2 9.2-22.9 11.9-34.9 6.9s-19.8-16.6-19.8-29.6l0-32-32 0c-30.2 0-58.7-14.2-76.8-38.4L121.6 172.8c-6-8.1-15.5-12.8-25.6-12.8l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32l64 0c30.2 0 58.7 14.2 76.8 38.4L326.4 339.2c6 8.1 15.5 12.8 25.6 12.8l32 0 0-32c0-12.9 7.8-24.6 19.8-29.6s25.7-2.2 34.9 6.9l64 64c6 6 9.4 14.1 9.4 22.6s-3.4 16.6-9.4 22.6l-64 64z"]
  };
  const faRotateLeft = {
    prefix: 'fas',
    iconName: 'rotate-left',
    icon: [512, 512, ["rotate-back", "rotate-backward", "undo-alt"], "f2ea", "M48.5 224L40 224c-13.3 0-24-10.7-24-24L16 72c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2L98.6 96.6c87.6-86.5 228.7-86.2 315.8 1c87.5 87.5 87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3c-62.2-62.2-162.7-62.5-225.3-1L185 183c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8L48.5 224z"]
  };
  const faQuestion = {
    prefix: 'fas',
    iconName: 'question',
    icon: [320, 512, [10067, 10068, 61736], "3f", "M80 160c0-35.3 28.7-64 64-64l32 0c35.3 0 64 28.7 64 64l0 3.6c0 21.8-11.1 42.1-29.4 53.8l-42.2 27.1c-25.2 16.2-40.4 44.1-40.4 74l0 1.4c0 17.7 14.3 32 32 32s32-14.3 32-32l0-1.4c0-8.2 4.2-15.8 11-20.2l42.2-27.1c36.6-23.6 58.8-64.1 58.8-107.7l0-3.6c0-70.7-57.3-128-128-128l-32 0C73.3 32 16 89.3 16 160c0 17.7 14.3 32 32 32s32-14.3 32-32zm80 320a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"]
  };
  const faBadgeDollar = {
    prefix: 'fas',
    iconName: 'badge-dollar',
    icon: [512, 512, [], "f645", "M256 0c36.8 0 68.8 20.7 84.9 51.1C373.8 41 411 49 437 75s34 63.3 23.9 96.1C491.3 187.2 512 219.2 512 256s-20.7 68.8-51.1 84.9C471 373.8 463 411 437 437s-63.3 34-96.1 23.9C324.8 491.3 292.8 512 256 512s-68.8-20.7-84.9-51.1C138.2 471 101 463 75 437s-34-63.3-23.9-96.1C20.7 324.8 0 292.8 0 256s20.7-68.8 51.1-84.9C41 138.2 49 101 75 75s63.3-34 96.1-23.9C187.2 20.7 219.2 0 256 0zm20.9 140.4c0-11-9-20-20-20s-20 9-20 20l0 17.4c-8.8 1.8-17.7 4.8-25.7 9.6c-15 8.9-27.7 24.4-27.6 46.9c.1 21.5 12.6 35.1 26.3 43.4c11.9 7.2 27 11.7 39.1 15.4l1.9 .6c13.9 4.2 24.4 7.6 31.7 12.2c6.1 3.8 7.5 6.8 7.6 11c.1 6.5-2.5 10.4-7.6 13.6c-6 3.7-15.2 5.9-25.1 5.6c-12.7-.4-24.6-4.4-39.7-9.6c0 0 0 0 0 0s0 0 0 0s0 0 0 0s0 0 0 0c-2.6-.9-5.2-1.8-8-2.7c-10.5-3.5-21.8 2.2-25.3 12.6s2.2 21.8 12.6 25.3c2.2 .7 4.5 1.5 6.8 2.3c9.7 3.3 20.9 7.2 32.8 9.6l0 18c0 11 9 20 20 20s20-9 20-20l0-17.1c9.3-1.7 18.6-4.9 26.8-10.1c15.3-9.5 26.8-25.7 26.5-48.1c-.3-21.6-12.4-35.6-26.3-44.3c-12.5-7.9-28.3-12.6-40.8-16.4l-.6-.2c-14.1-4.3-24.6-7.5-31.9-11.9c-6.2-3.7-7-6.3-7-9.3c0-5.1 2.1-8.8 7.9-12.3c6.5-3.8 16-6 25-5.8c10.9 .2 22.9 2.6 35.2 5.8c10.7 2.8 21.6-3.5 24.5-14.2s-3.5-21.6-14.2-24.5c-7.8-2.1-16.3-4.1-25.1-5.4l0-17.3z"]
  };
  const faSliders = {
    prefix: 'fas',
    iconName: 'sliders',
    icon: [512, 512, ["sliders-h"], "f1de", "M0 416c0 17.7 14.3 32 32 32l54.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 448c17.7 0 32-14.3 32-32s-14.3-32-32-32l-246.7 0c-12.3-28.3-40.5-48-73.3-48s-61 19.7-73.3 48L32 384c-17.7 0-32 14.3-32 32zm128 0a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM320 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm32-80c-32.8 0-61 19.7-73.3 48L32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l246.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48l54.7 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-54.7 0c-12.3-28.3-40.5-48-73.3-48zM192 128a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm73.3-64C253 35.7 224.8 16 192 16s-61 19.7-73.3 48L32 64C14.3 64 0 78.3 0 96s14.3 32 32 32l86.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 128c17.7 0 32-14.3 32-32s-14.3-32-32-32L265.3 64z"]
  };
  const faXmark = {
    prefix: 'fas',
    iconName: 'xmark',
    icon: [384, 512, [128473, 10005, 10006, 10060, 215, "close", "multiply", "remove", "times"], "f00d", "M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"]
  };
  const faChevronRight = {
    prefix: 'fas',
    iconName: 'chevron-right',
    icon: [320, 512, [9002], "f054", "M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"]
  };
  const faPersonSeat = {
    prefix: 'fas',
    iconName: 'person-seat',
    icon: [384, 512, [], "e21e", "M144 128a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm48 160l0-32 64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-66.7 0c-6.6-18.6-24.4-32-45.3-32c-26.5 0-48 21.5-48 48l0 80c0 35.3 28.7 64 64 64l85.6 0c8.1 0 14.9 6 15.9 14l10.7 86c2 16 15.6 28 31.8 28l48 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-19.8 0L325 358.1c-5-40-39-70.1-79.4-70.1L192 288zM64 160c0-17.7-14.3-32-32-32s-32 14.3-32 32L0 320c0 70.7 57.3 128 128 128l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-35.3 0-64-28.7-64-64l0-160z"]
  };
  const faCheck = {
    prefix: 'fas',
    iconName: 'check',
    icon: [448, 512, [10003, 10004], "f00c", "M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"]
  };
  const faTriangleExclamation = {
    prefix: 'fas',
    iconName: 'triangle-exclamation',
    icon: [512, 512, [9888, "exclamation-triangle", "warning"], "f071", "M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480L40 480c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24l0 112c0 13.3 10.7 24 24 24s24-10.7 24-24l0-112c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"]
  };
  const faExclamationTriangle = faTriangleExclamation;

  exports.faBadge = faBadge;
  exports.faBadgeDollar = faBadgeDollar;
  exports.faCheck = faCheck;
  exports.faChevronRight = faChevronRight;
  exports.faExclamationTriangle = faExclamationTriangle;
  exports.faPersonSeat = faPersonSeat;
  exports.faQuestion = faQuestion;
  exports.faRotateLeft = faRotateLeft;
  exports.faShuffle = faShuffle;
  exports.faSliders = faSliders;
  exports.faXmark = faXmark;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
